<template>

  <section  class="uk-section uk-section-secondary uk-section-large">
    <div class="uk-container">

        <div class="no-print uk-margin-medium-top ">

        </div>

            <h1><span v-if="id">Editar</span><span v-else>Nuevo</span>  Roadmap<span v-if="roadmap.name">: {{roadmap.name}}</span> </h1>
               <div v-if="id">
                <hr><p class="uk-text-center"><button @click="save" class="uk-button uk-button-primary uk-button-large">Guardar Roadmap</button></p><hr>
               </div>
            
            <p v-if="id"> Liga al roadmap: <a :href="'https://cannonsoft.dev/roadmap/'+id">https://cannonsoft.dev/roadmap/{{id}}</a> </p>
            
            <p>Llena los datos</p>

            <form @submit.prevent="">
                <fieldset class="uk-fieldset">
                    <input type="text" class="uk-input" v-model="roadmap.name" placeholder="Nombre del proyecto">
                </fieldset>
            </form>

            <hr>

            <h3>Entregables del proyecto</h3>

            <p>Agrega entregables</p>

            <form @submit.prevent="addDelivery">
                <input type="text" v-model="delivery.desc" placeholder="Desc." class="uk-input">
                <input type="text" v-model="delivery.url" placeholder="URL" class="uk-input">
                <input type="text" v-if="!delivery.btn" v-model="delivery.btntxt" placeholder="Texto del botón" class="uk-input">
                <input type="text" v-if="!delivery.btntxt" v-model="delivery.btn" placeholder="URL Imagen botón" class="uk-input">
                <label >Fecha de entrega <br></label>
                <input type="date" v-model="delivery.date" placeholder="URL" class="uk-input">

                <br><br>

                <button class="uk-button uk-button-secondary">Agregar Entregable</button>
            </form>

            <div v-if="roadmap.entregables">
                <div v-if="Object.keys(roadmap.entregables).length > 0">
                    <p> <br> Entregables:</p>
                    <ol>
                        <li v-for="item,index in roadmap.entregables">
                            <div uk-grid class="uk-child-width-1-3@s">
                                <div>{{item.desc}} - Entrega: {{new Date(parseInt(item.date)).toLocaleDateString()}}</div>
                            </div>
                            <div>
                                <a v-if="item.btntxt" :href="item.url" target="_blank" class="uk-button uk-button-primary">{{item.btntxt}}</a>
                                <a v-if="item.btn" :href="item.url" target="_blank" ><img :src="item.btn" alt=""></a>
                            </div>
                            <div>
                                <button @click="delete roadmap.entregables[index]" class="uk-button uk-button-default"><span uk-icon="trash" >  </span></button>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
                

            <hr>

            <h3>Características del proyecto</h3> 

                <p>Agrega características</p>

            <form @submit.prevent="addFeature">
                <div uk-grid class="uk-child-width-1-3@s">

                    <div>
                        <label >Nombre <br></label>
                        <input type="text" class="uk-input" v-model="feature.name" placeholder="Nombre">
                    </div>
                    <div>
                        <label >Descripción <br></label>
                        <input type="text" class="uk-input" v-model="feature.desc" placeholder="Descripción">
                    </div>
                    <div>
                        <label >Estado <br></label>
                        <select class="uk-select" v-model="feature.state">
                            <option value="done">Completado</option>
                            <option value="dev">En desarrollo o esperando cambios</option>
                            <option value="none">Por desarrollar/ En espera</option>
                        </select>   
                    </div>

                </div>
            </form>
            <form class="uk-margin uk-margin-medium-left" @submit.prevent="addSubItem">
                <p>
                       Agregar sub-item 
                </p>
                <div uk-grid class="uk-child-width-1-4@s">

                    <div>
                        <label >Nombre <br></label>
                        <input type="text" class="uk-input" v-model="subitem.name" placeholder="Nombre">
                    </div>
                    <div>
                        <label >Descripción <br></label>
                        <input type="text" class="uk-input" v-model="subitem.desc" placeholder="Descripción">
                    </div>
                    <div>
                        <label >Estado <br></label>
                        <select class="uk-select" v-model="subitem.state">
                            <option value="done">Completado</option>
                            <option value="dev">En desarrollo o esperando cambios</option>
                            <option value="none">Por desarrollar/ En espera</option>
                        </select>   
                    </div>
                    <div>
                        <br>
                        <button class="uk-button uk-button-default" type="button" @click="addSubItem"> <span uk-icon="plus-circle"></span>&nbsp;Agregar sub-item</button>
                    </div>

                </div>
            </form>
            <p >Subitems</p>
                <table  class="uk-table uk-table-small uk-table-striped uk-width-1-2@l uk-table-responsive uk-table-hover">
                    <thead>
                        <th>Nombre</th>
                        <th>Desc.</th>
                        <th>Estado</th>
                    </thead>
                    <tr v-for="item,index in feature.subitems">
                        <td>{{item.name}}</td>
                        <td>{{item.desc}}</td>
                        <td>{{item.state}}</td>
                    </tr>
                </table>

                    <div>
                        <button class="uk-button uk-button-primary" @click="addFeature"> <span uk-icon="plus-circle"></span>&nbsp; Agregar Característica</button>
                    </div>


                <hr>
                <h4 >CARACTERÍSTICAS</h4>

                
                <div uk-grid="masonry: true" class="uk-child-width-1-2@s ">
                    <div v-for="feat,index in roadmap.features" >
                        <div  :style="'background-color:'+getColor(feat.state)" class="uk-margin uk-card uk-card-default uk-background-muted uk-dark uk-card-body uk-border-rounded">
                            <h4 ><a style="color:black" href="#" @click="delete roadmap.features[index]"><span uk-icon="trash"></span></a>   &nbsp; <b> {{Object.keys(roadmap.features).indexOf(index)+1}} - {{feat.name}} </b></h4>

                                <div uk-grid>
                                    <div class="uk-width-3-4@m">
                                        <div class="uk-inline uk-width-1-1">
                                            <span style="color:black;" class="uk-form-icon" data-uk-icon="icon: pencil"></span>
                                            <input style="color:black;" type="text" class="uk-input uk-dark" v-model="feat.name" placeholder="Nombre">
                                        </div>
                                        <input style="color:black;" type="text" class="uk-input" v-model="feat.desc" placeholder="Desc.">
                                        <label >Estado <br></label>
                                        <select style="color:black;" class="uk-select" v-model="feat.state">
                                            <option value="done">Completado</option>
                                            <option value="dev">En desarrollo o esperando cambios</option>
                                            <option value="none">Por desarrollar/ En espera</option>
                                        </select>   
                                    </div>
                                </div>
                                        


                               <hr>

                 
                                <h4 style="color:white;" class="uk-text-left">
                                     <b>Sub-items </b>
                                </h4> 


                                <div uk-grid class="uk-margin uk-child-width-1-2@s">
                                    <div v-for="sub,subID in feat.subitems">
                                        <div :style="'color:black;background-color:'+getColor(sub.state)" class="uk-border-rounded uk-card uk-card-body "  >
                                            <button style="color:black;" class="uk-modal-close-default uk-close-large" type="button" uk-close @click="delete feat.subitems[subID]"></button>
                                            <p style="color:black;">{{sub.name}}</p>
                                            <input style="color:black;" type="text" class="uk-input" v-model="sub.name" placeholder="Nombre">
                                            <input style="color:black;" type="text" class="uk-input" v-model="sub.desc" placeholder="Desc.">
                                            <label >Estado <br></label>
                                            <select style="color:black;" class="uk-select" v-model="sub.state">
                                                <option value="done">Completado</option>
                                                <option value="dev">En desarrollo o esperando cambios</option>
                                                <option value="none">Por desarrollar/ En espera</option>
                                            </select>   
                                        </div>
                                    </div>
                                        <div class="uk-flex uk-flex-center uk-flex-middle">
                                                <button style="color:black;" type="button" uk-tooltip="Agregar sub-item" @click="feat.subitems[new Date().getTime()] = {name:'',desc:'',state:''}" class="uk-button uk-button-text"> <br> <span uk-icon="ratio:2;icon:plus-circle"></span> <br></button>                                                    
                                        </div>
                                </div>
                                        
                                
                        </div>
                    </div>
                    <div>
                        <div class="uk-flex uk-flex-center uk-flex-middle">
                            <button style="font-size:22px" type="button" uk-tooltip="Agregar Característica" @click="roadmap.features[new Date().getTime()] = {name:'',desc:'',state:'',subitems:{}}" class="uk-button uk-button-text"> <br> <span uk-icon="ratio:1.5;icon:plus-circle"></span>  Agregar Característica <br></button>
                        </div>
                        
                    </div>
                </div>

                    <hr>

                <p class="uk-text-center"><button @click="save" class="uk-button uk-button-primary uk-button-large">Guardar Roadmap</button></p>
    </div>


  </section>

</template>

<script>
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from 'vue-router';
export default {
  setup() {
    const roadmap = ref({
        features : {},
        entregables : {}
    });
    const feature = ref({
        subitems : {}
    })
    const delivery = ref({})

    const addDelivery = ()=>{
        if(!roadmap.value.entregables)roadmap.value.entregables={}
        delivery.value.date = new Date(delivery.value.date).getTime()
        if(isNaN(delivery.value.date)) delivery.value.date = 'Invalid Date'
        roadmap.value.entregables[String(new Date().getTime())] = delivery.value
        delivery.value = {}
    }

    const addFeature = ()=>{
        roadmap.value.features[String(new Date().getTime())] = feature.value
        feature.value = {
            subitems : {}
        }
    }
    const subitem = ref({})
    const addSubItem = ()=>{
        feature.value.subitems[String(new Date().getTime())] = subitem.value
        subitem.value = {}
    }

    const getColor = (state)=>{
        switch (state) {
            case 'done':
                return 'lightgreen';
            case 'dev':
                return 'gold';
            default:
                return 'lightgrey';
        }
    }

    const route = useRoute()
    const rotuer = useRouter()
    const id = ref('')
    if(route.params.id){
        firebase.database().ref('site/roadmaps/'+route.params.id).once('value').then((s)=>{
            if(s.exists()){
                roadmap.value = s.toJSON()
                id.value = route.params.id
            }
        })
    }
    const setID = ()=>{
        if(id.value){return}

        if(roadmap.value.name && roadmap.value.name.length >= 3){
            let nombre = roadmap.value.name.split(' ')
            let id = ''
            if(nombre.length > 1){
                for(let word in nombre){
                    if(nombre[word]){
                        id += nombre[word].toLowerCase().slice(0,3).match(/[a-z0-9]/g).join('')
                    }
                }
            } else {
                let n = nombre[0].toLowerCase()
                let m = n.match(/[a-z0-9]/g)
                id = m.join('')
            }
            roadmap.value.id = id
        }

    }
    const save = ()=>{
        setID()
        if(firebase.auth().currentUser){
            firebase.database().ref('site/roadmaps/'+roadmap.value.id).update(roadmap.value).then(()=>{
                UIkit.notification('Guardado','success')
            })
        }  else {
            rotuer.push('/login') 
        }
    }

    return {
      roadmap,
      getColor,
      save,
      id,
      route,

      feature,
      addFeature,
      subitem,
      addSubItem,

      delivery,
      addDelivery,

    };
  },
};
</script>

<style>
</style>